<template>
  <v-app>
    <v-main class="bgMinsal">
      <v-container fill-height justify-center fluid>
        <v-flex xs12 sm8 md6 lg4 xl3>
          <v-img
              contain
              class="mt-10 mx-auto d-flex mb-6"
              src="@/assets/img/comprasal_login.png"
              max-width="380"
            />
          <v-card class=" mt-10  px-2 px-sm-12 elevation-2">
            <v-card-title class="justify-center secondary--text" primary-title
            >Iniciar sesión
            </v-card-title
            >
            <v-card-text>
              <div class="body-1 my-2">
                Debe establecer su contraseña para iniciar sesión
              </div>
              <v-row class="mt-6">
                <v-col class="pa-0 ma-0 cols-12">
                  <v-text-field
                      id="new_pass"
                      name="new_pass"
                      v-model="form.password"
                      @blur="$v.form.password.$touch()"
                      label="Contraseña nueva*"
                      :type="password_show ? 'text' : 'password'"
                      :error-messages="passwordErrors"
                  >
                    <template #append>
                      <v-icon
                          v-if="password_show === false"
                          @click="password_show = !password_show"
                      >mdi-eye-outline
                      </v-icon
                      >
                      <v-icon v-else @click="password_show = !password_show"
                      >mdi-eye-off-outline
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pa-0 ma-0">
                  <v-text-field
                      id="repeat_pass"
                      name="repeat_pass"
                      v-model="form.confirm_password"
                      @blur="$v.form.confirm_password.$touch()"
                      label="Repita la contraseña*"
                      :type="confirm_show ? 'text' : 'password'"
                      :error-messages="confirmPasswordErrors"
                  >
                    <template #append>
                      <v-icon
                          v-if="confirm_show === false"
                          @click="confirm_show = !confirm_show"
                      >mdi-eye-outline
                      </v-icon
                      >
                      <v-icon
                          v-else
                          @click="confirm_show = !confirm_show"
                      >mdi-eye-off-outline
                      </v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-space-around pb-5" width="100%">
              <v-btn
                  color="secondary white--text"
                  :loading="loading"
                  @click="changePassword"
              >
                <!-- <v-icon left>mdi-content-save</v-icon> -->
                Confirmar
              </v-btn>
              <!-- <v-divider vertical></v-divider> -->
              <!-- <router-link :to="{name:'login'}" class="blueMinsal--text"
              >Ir al login
              </router-link
              > -->
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import {required, minLength, sameAs, helpers} from "vuelidate/lib/validators";

const alpha = helpers.regex('alpha', /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!%*?&-_.:])([A-Za-zd$@$!%*?&]|[^ d]){8,20}$/)
export default {
  name: "resetPasswordView",
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        alpha,
      },
      confirm_password: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs('password')
      },
    }
  },
  data: () => ({
    password_show: false,
    confirm_show: false,
    loading: false,
    form: {
      password: null,
      confirm_password: null
    }
  }),
  methods: {
    async changePassword() {
      if (!this.$v.$invalid) {
        try {
          this.loading = true
          const token = this.$route.params.id;
          await this.services.Auth.newPassword(this.form, token)
          this.temporalAlert({
            show: true,
            message: "Contraseña actualizada con éxito",
            type: "success",
          });
          await this.$router.push({name: 'identidad-digital'});
        } catch {

        } finally {
          this.loading = false
        }
      }
    },
  },
  computed: {
    passwordErrors() {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required && errors.push('La contraseña es obligatoria')
      !this.$v.form.password.minLength && errors.push('El mínimo de caracteres 8')
      !this.$v.form.password.alpha && errors.push('Tiene que tener mayúsculas, minúsculas y números')
      return errors
    }, confirmPasswordErrors() {
      const errors = []
      if (!this.$v.form.confirm_password.$dirty) return errors
      !this.$v.form.confirm_password.required && errors.push('La contraseña es obligatoria')
      !this.$v.form.confirm_password.minLength && errors.push('El mínimo de caracteres 8')
      !this.$v.form.confirm_password.sameAsPassword && errors.push('Las contraseñas no coinciden')
      return errors
    },
  },
};
</script>